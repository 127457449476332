import { useRef } from "react";
import axios from "axios";

function SubscriptionForm() {
  const usernameRef = useRef(null);
  const mailRef = useRef(null);
  const phoneRef = useRef(null);

  async function postData(e) {
    e.preventDefault();
    if (
      !usernameRef.current.value ||
      !mailRef.current.value ||
      !phoneRef.current.value
    ) {
      alert("Favor diligencie todos los datos");
      return;
    }
    const user = {
      username: usernameRef.current.value,
      mail: mailRef.current.value,
      phone: phoneRef.current.value,
    };
    try {
      const mydata = await axios({
        method: "POST",
        url: "https://akl3hm9dfi.execute-api.us-east-1.amazonaws.com/mylambda",
        data: user,
        headers: {
          "Content-Type": "text/plain",
        },
      });
      if (mydata.status === 200) {
        alert("Datos enviados");
        window.location.reload(true);
      }
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className="subscription-form-container">
      <div className="subscription-form-title">
        <h2>
          Suscríbete para recibir consejos de salud e información sobre próximos
          eventos
        </h2>
      </div>

      <form className="subscription-form-container">
        <input placeholder="Escribe tu nombre" required ref={usernameRef} />
        <input placeholder="Escribe tu Email" required ref={mailRef} />
        <input
          placeholder="Escribe tu número de teléfono"
          required
          ref={phoneRef}
        />
        <button onClick={postData}> Enviar</button>
      </form>
    </div>
  );
}

export default SubscriptionForm;
