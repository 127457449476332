import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import Header from "../header/Header";

function Blog() {
  const location = useLocation();
  const [currentBlog, setCurrentBlog] = useState([]);

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.post(
          "https://9z4ll81nc1.execute-api.us-east-1.amazonaws.com/mylambda2"
        );
        const blogs2 = await response.data;
        setBlogs(blogs2);
        let current = blogs2.filter(
          (item) => item.title === localStorage.getItem("blog")
        );
        setCurrentBlog(current[0]);
      } catch (e) {
        console.error(e);
      }
    }
    fetchBlogs();
  }, [localStorage.getItem("blog")]);

  useEffect(() => {
    ReactGA.set({
      page: location.pathname,
      title: location.pathname,
    });
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    ReactGA.event({
      category: "Page View",
      action: window.location.pathname,
      label: window.location.pathname,
    });
  }, [location]);
  return (
    <div className="blog-container">
      <Header blogs={blogs} />
      <h1 className="blog-container-title">{currentBlog.title}</h1>
      <div className="blog-content">
        <div className="blog-content-left-section">
          <div dangerouslySetInnerHTML={{ __html: currentBlog.content1 }} />
          <img src={currentBlog.image1} alt="medicina interna" />
        </div>
        <div className="blog-content-right-section">
          <div>
            <img src={currentBlog.image2} alt="medicina interna" />
            <div dangerouslySetInnerHTML={{ __html: currentBlog.content2 }} />
          </div>
        </div>
      </div>
      <div className="blog-content-end-paragraph">
        <div className="blog-content-end-paragraph-img-container">
          <img src={currentBlog.image3} alt="medicina interna" />
        </div>

        <div
          className="blog-content-end-paragraph-content"
          dangerouslySetInnerHTML={{ __html: currentBlog.content3 }}
        />
      </div>
    </div>
  );
}

export default Blog;
