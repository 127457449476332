import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import OpinionCard from "../OpinionCard/OpinionCard";
import Icon1 from "../../media/opinion1.jpg";
import Icon2 from "../../media/opinion2.jpg";
import Icon3 from "../../media/opinion3.jpg";
import Icon4 from "../../media/opinion4.jpg";

function OpinionCarousel() {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={3000}
      showThumbs={false}
    >
      <OpinionCard
        image1={Icon1}
        text1={
          "“El Dr Garcia siempre revisa todos mis examenes y me los explica”"
        }
        image2={Icon2}
        text2={"”El Doctor Gustavo García me quitó la insulina!”"}
      />
      <OpinionCard
        image1={Icon3}
        text1={"”Agradecido enormemente con este maravilloso profesional”"}
        image2={Icon4}
        text2={"”El Doctor Gustavo García es muy amable y me da confianza!”"}
      />
    </Carousel>
  );
}

export default OpinionCarousel;
