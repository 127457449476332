import "../../../src/global.css";
import { useEffect, useState } from "react";
import Header from "../header/Header";
import TopBanner from "../top-banner/TopBanner";
import ProfileInfo from "../profile-info/ProfileInfo";
import SubscriptionForm from "../subscription-form/SubscriptionForm";
import OpinionCarousel from "../Carousel/Carousel";
import AskSection from "../AskSection/AskSection";
import Whatsapp from "../../media/whatsapp.svg";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

function Home() {
  const [blogs, setBlogs] = useState([]);

  const location = useLocation();

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.post(
          "https://9z4ll81nc1.execute-api.us-east-1.amazonaws.com/mylambda2"
        );
        const blogs2 = await response.data;
        setBlogs(blogs2);
      } catch (e) {
        console.error(e);
      }
    }
    fetchBlogs();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    ReactGA.event({
      category: "Page View",
      action: "Blog Click",
      label: window.location.pathname,
    });
  }, [location]);
  return (
    <div className="App">
      <Helmet>
        <script src="https://staticnew-prod.topdoctors.com.co/static/widgets/main.ccbde400697ece66.js#type=6&apikey=jVCmI4UHlS3agoi8_hrzqyQTaL36dw%3D%3D&environment=prod&config=W29iamVjdCBPYmplY3Rd&country=co&storage=https%3A%2F%2Fstaticnew-prod.topdoctors.com.co&apiurl=https%3A%2F%2Fapi-ltm-prod.topdoctors.es&hostname=https%3A%2F%2Fwww.topdoctors.com.co"></script>
        <script src="https://staticnew-prod.topdoctors.com.co/static/widgets/main.ccbde400697ece66.js#type=9&apikey=jVCmI4UHlS3agoi8_hrzqyQTaL36dw%3D%3D&environment=prod&config=dW5kZWZpbmVk&country=co&storage=https%3A%2F%2Fstaticnew-prod.topdoctors.com.co&apiurl=https%3A%2F%2Fapi-ltm-prod.topdoctors.es&hostname=https%3A%2F%2Fwww.topdoctors.com.co" />
      </Helmet>

      <Header blogs={blogs} />
      <TopBanner />
      <div className="card-wrapper">
        <SubscriptionForm />
        <ProfileInfo />
      </div>
      <OpinionCarousel />
      <AskSection />
      <h1>Blogs</h1>
      <div className="blogs-footer">
        {blogs.map((blog) => (
          <div className="footer-item">{blog.title}</div>
        ))}
      </div>
      <a href="https://wa.me/573163240375" target="_blank">
        <img
          className="whatsapp-sticky"
          src={Whatsapp}
          alt="medicina interna"
        />
      </a>
    </div>
  );
}

export default Home;
