import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Home/Home";
import Blog from "../Blog/Blog";
import BlogCreator from "../BlogCreator/BlogCreator";
import axios from "axios";

export default function Router() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.post(
          "https://9z4ll81nc1.execute-api.us-east-1.amazonaws.com/mylambda2"
        );
        const blogs2 = await response.data;
        setBlogs(blogs2);
      } catch (e) {
        console.error(e);
      }
    }
    fetchBlogs();
  }, []);

  const removeAccents = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/inicio" />}></Route>
        <Route exact path="/inicio" element={<Home />}></Route>
        <Route exact path="/blog-creator" element={<BlogCreator />}></Route>
        {blogs.map((blog) => (
          <Route
            exact
            path={`/${removeAccents(blog.title)}`}
            element={<Blog />}
          ></Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}
