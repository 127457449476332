function AskSection() {
  return (
    <div className="ask-section-container">
      <h1>Preguntas Frecuentes</h1>
      <div className="ask-section-card">
        <h3>Que es la medicina natural o alternativa?</h3>
        <p>
          La medicina alternativa es un tratamiento para ayudar a la salud. Sin
          embargo, a diferencia de la medicina "tradicional" que usan los
          médicos, la medicina alternativa no forma parte de los tratamientos
          que los doctores suelen recetar. Esto significa que en vez de usar los
          medicamentos que los médicos recomiendan, algunas personas pueden
          elegir usar tratamientos naturales. Pero es importante saber que la
          medicina alternativa no siempre ha sido probada científicamente y
          puede no ser tan segura o efectiva como los tratamientos médicos
          tradicionales. Por lo tanto, siempre es importante hablar con un
          médico antes de decidir usar cualquier tipo de tratamiento, ya sea
          alternativo o tradicional.
        </p>
      </div>
      <div className="ask-section-card">
        <h3>Que es la medicina integrativa?</h3>
        <p>
          La medicina integrativa es un tipo de práctica médica que se basa en
          el uso de tratamientos complementarios para ayudar a tratar una
          enfermedad. Estos tratamientos complementarios no son los tratamientos
          médicos habituales o tradicionales, pero han sido probados
          científicamente para ser seguros y efectivos. Por lo tanto, cuando una
          persona usa medicina integrativa, también está utilizando los
          tratamientos médicos habituales que su médico les recomienda. La
          medicina integrativa combina ambos tipos de tratamiento para lograr el
          mejor resultado posible para la persona que está enferma.
        </p>
      </div>
      <div className="ask-section-card">
        <h3>Y los remedios a base de hierbas?</h3>
        <p>
          Los remedios a base de hierbas son medicinas que se hacen con
          sustancias que se encuentran en las plantas. A menudo vienen en forma
          de píldoras o líquidos que se pueden tomar. A pesar de que estos
          remedios son "naturales" y pueden ayudar con ciertas enfermedades, no
          siempre son seguros. Esto se debe a que algunas plantas contienen
          sustancias químicas que pueden ser dañinas para nuestro cuerpo.
          Además, estas sustancias pueden interactuar con otros medicamentos que
          una persona ya esté tomando, lo que puede causar problemas.
        </p>
      </div>
      <div className="ask-section-card">
        <h3>Que son los suplementos alimentarios?</h3>
        <p>
          Los suplementos alimentarios son pastillas o líquidos que contienen
          vitaminas, minerales y otras sustancias que el cuerpo podría necesitar
          para funcionar bien y mantenerse saludable. Aunque pueden ser útiles
          para algunas personas que no obtienen suficientes nutrientes de su
          dieta, por lo general es mejor obtener estos nutrientes a través de
          una variedad de alimentos saludables en lugar de tomar suplementos. En
          algunos casos, los médicos pueden recetar suplementos a las personas
          si necesitan una cantidad específica de ciertos nutrientes, como el
          calcio y la vitamina D para mantener los huesos fuertes.
        </p>
      </div>
      <div className="ask-section-card">
        <h3>Que es la homeopatia?</h3>
        <p>
          La homeopatía es una forma de medicina alternativa que utiliza
          pequeñas cantidades de sustancias para tratar enfermedades. Aunque
          estas sustancias podrían ser perjudiciales en cantidades mayores, los
          homeópatas consideran que al diluir la sustancia se convierte en un
          remedio efectivo. La idea es que la sustancia que causa los síntomas
          de la enfermedad también puede ser utilizada para tratar la misma
          enfermedad. Algunos creen que los efectos positivos que las personas
          experimentan son simplemente un efecto placebo, es decir, que la
          persona cree que el tratamiento funciona y por lo tanto mejora sus
          síntomas.
        </p>
      </div>
    </div>
  );
}

export default AskSection;
