import ProfileImage from "../../media/profile1.jpg";

function ProfileInfo() {
  return (
    <div className="profile-info-container">
      <div className="profile-info-left-section">
        <p>
          Con el Dr. Garcia a tu lado, puedes estar seguro de que recibirás la
          atención médica que mereces y necesitas para mejorar tu calidad de
          vida.<br></br>
          <br></br> Su amabilidad te guiará hacia una vida más saludable.
          ¡Agenda tu cita hoy mismo y comienza a sentir la diferencia!
        </p>
      </div>
      <div
        id="topdoctors-co-widget-container-6"
        style={{ maxWidth: "360px" }}
      ></div>

      <div className="profile-info-right-section">
        <img src={ProfileImage} alt="gustavo garcia" />
      </div>
    </div>
  );
}

export default ProfileInfo;
