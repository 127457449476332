import React from "react";

function OpinionCard({ image1, image2, text1, text2 }) {
  return (
    <div className="opinion-card-container">
      <div className="opinion-card">
        <div className="opinion-card-img-container">
          <img className="opinion-card-img" src={image1} />
        </div>
        <p>{text1}</p>
      </div>
      <div className="opinion-card">
        <div className="opinion-card-img-container">
          <img className="opinion-card-img" src={image2} />
        </div>
        <p>{text2}</p>
      </div>
    </div>
  );
}

export default OpinionCard;
