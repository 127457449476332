import "../src/global.css";
import Router from "./components/Router/index";
import ReactGA from "react-ga4";

ReactGA.initialize("G-5VGGNYXKC6");

function App() {
  return <Router />;
}

export default App;
