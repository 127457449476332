import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BlogCreator() {
  const [value, setValue] = useState("");

  return (
    <>
      <div>
        <h1>Creador de Blog</h1>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
        <h1>Codigo a pegar en Mongo</h1>
        <div>{value}</div>
      </div>
    </>
  );
}

export default BlogCreator;
