import React , {useState, useEffect} from "react";
import ImagenBanner from "../../media/banner1.jpg";
import ImagenBannerMobile from "../../media/banner1mobile.jpg";

const BannerCard = ({ iconName, text, color }) => {
  return (
    <div className="banner-card" style={{ backgroundColor: color }}>
      <span className="material-symbols-outlined" style={{ fontSize: "4vw" }}>
        {iconName}
      </span>
      <p>{text}</p>
    </div>
  );
};

function TopBanner() {
  const [imageSrc, setImageSrc] = useState("");
  const windowWidth = window.innerWidth

 useEffect(() =>{
  const handleResize = () =>{
    if(window.matchMedia('(max-width: 600px)').matches){
      setImageSrc(ImagenBannerMobile)
    }else{
      setImageSrc(ImagenBanner)
    }
  }
  handleResize()
  window.addEventListener('resize', handleResize)
  return () => window.removeEventListener('resize',handleResize)
  },[windowWidth])

  return (
    <div className="top-banner">
      <img src={imageSrc} alt="medicina interna" />
      <h1 className="top-banner-image-text">
        <span>Especialista</span>
        <span>en</span>
        <span>Medicina </span>
        <span>Interna</span>
      </h1>
{/* 
      <div className="banner-cards-container">
        <BannerCard
          iconName="stethoscope"
          text="Profesionalismo"
          color="rgba(85, 150, 224, 0.692)"
        />
        <BannerCard
          iconName="approval_delegation"
          text="Calidad Humana"
          color="rgba(51, 129, 218, 0.692)"
        />
        <BannerCard
          iconName="badge"
          text="Más de 20 años de experiencia"
          color="rgba(51, 120, 224, 0.692)"
        />
        <BannerCard
          iconName="prescriptions"
          text="Atención Personalizada"
          color="rgba(18, 115, 226, 0.692)"
        />
      </div> */}
    </div>
  );
}

export default TopBanner;
