import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Header({ blogs }) {
  const navigate = useNavigate();
  const [Headerclassname, setHeaderclassname] = useState("top-header");
  const [dropdownclassname, setDropdownclassname] = useState(
    "custom-header-dropdown"
  );
  const [currentScroll, setCurrentScroll] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setCurrentScroll(window.scrollY);
    currentScroll > 100
      ? setHeaderclassname("top-header-transparent")
      : setHeaderclassname("top-header");
    currentScroll > 100
      ? setDropdownclassname("custom-header-dropdown-transparent")
      : setDropdownclassname("custom-header-dropdown");
  }, [currentScroll]);

  const handleScroll = () => {
    setCurrentScroll(window.scrollY);
  };

  const removeAccents = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleClick = (e) => {
    let menu = e.target.id;
    ReactGA.event({
      category: "Page View",
      action: removeAccents(menu),
      label: removeAccents(menu),
    });
    localStorage.setItem("blog", e.target.id);
    navigate(`/${removeAccents(menu)}`);
  };

  return (
    <div className={Headerclassname}>
      <Helmet>
        <script src="https://staticnew-prod.topdoctors.com.co/static/widgets/main.ccbde400697ece66.js#type=9&apikey=jVCmI4UHlS3agoi8_hrzqyQTaL36dw%3D%3D&environment=prod&config=dW5kZWZpbmVk&country=co&storage=https%3A%2F%2Fstaticnew-prod.topdoctors.com.co&apiurl=https%3A%2F%2Fapi-ltm-prod.topdoctors.es&hostname=https%3A%2F%2Fwww.topdoctors.com.co" />
      </Helmet>
    {/*   <div
        id="topdoctors-co-widget-container-9"
        style={{ maxWidth: " 245px;" }}
      ></div> */}

      <h1 className="custom-header-left">Dr Gustavo Garcia</h1>

      <div className="custom-header-right">
      {/*   <h1 className="custom-header-sesion">Login</h1> */}
        <h1 className="custom-header-blog">
          Blog
          <div className={dropdownclassname}>
            <ul>
              {blogs.map((blog) => (
                <li
                  key={blog.title}
                  id={blog.title}
                  className="custom-header-dropdown-li"
                  onClick={handleClick}
                >
                  {blog.title}
                </li>
              ))}
            </ul>
          </div>
        </h1>
      </div>
    </div>
  );
}

export default Header;
